<template>
    <b-row class="match-height">
      <b-col
lg="12"
> <b-card
      title="Edit Material Request"
    >
      <b-form
          @submit.prevent="submitEditMaterialRequest"
>
        <b-row>
          <b-col cols="12">
            <b-button
            type="button"
            variant="primary"
            class="mr-1"
            @click="previous()"
            >
            <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
            />
            Back
            </b-button>
            <div class="devider" />
            <b-form-group
              label="To *"
              label-for="material_request_to"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_to"
                v-model="materialRequestTo"
                placeholder="To."
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="CC"
              label-for="material_request_cc"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_cc"
                v-model="materialRequestCc"
                placeholder="CC"
                required="required"
                readonly
              />
            </b-form-group>
            <b-form-group
              label="Reason"
              label-for="material_request_reason"
              label-cols-md="12"
            >
              <b-form-input
                id="material_request_reason"
                v-model="materialRequestReason"
                placeholder="Reason"
                required="required"
                readonly
              />
            </b-form-group>
            <b-row>
              <b-col
cols="3"
class="text-center font-weight-bold"
>
                Item name
              </b-col>
              <b-col
cols="2"
class="text-center font-weight-bold"
>
                Unit name
              </b-col>
              <b-col
cols="2"
class="text-center font-weight-bold"
>
                Requested QTY
              </b-col>
              <b-col
cols="2"
class="text-center font-weight-bold"
>
                Accepted QTY
              </b-col>
              <b-col
cols="2"
class="text-center font-weight-bold"
>
                Sent QTY
              </b-col>
              <b-col
cols="1"
class="text-center font-weight-bold"
>
                Is Final
              </b-col>
              </b-row>
             <p />
            <b-row
v-for="(chosen) in chosens"
:key="chosen.value"
>
            <br>
              <b-col cols="3 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                    {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="2 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                    {{ chosen.unit_name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              <b-col cols="2 text-center">
              <b-form-input
                v-model="chosen.qty"
                placeholder="Qty"
                min="1"
                readonly
              />
              </b-col>
              <b-col cols="2 text-center">
              <b-form-input
                v-model="chosen.qty_accepted"
                placeholder="Qty Accepted"
                min="1"
                readonly
              />
              </b-col>
              <b-col cols="2 text-center">
              <b-form-input
                v-if="materialRequestSent==false"
                v-model="chosen.qty_sent"
                type="number"
                placeholder="Qty"
                min="1"
                required="required"
                :max="chosen.qty_accepted"
              />
              <b-form-input
                v-if="materialRequestSent==true"
                v-model="chosen.qty_sent"
                placeholder="Qty"
                min="1"
                readonly
              />
              </b-col>
              <b-col cols="1">
              <b-form-checkbox
                v-model="chosen.is_final"
                class="mb-1"
              />
              </b-col>
              <b-col cols="12">
                <br>
              </b-col>
              </b-row>
          </b-col>
          <!-- submit and reset -->
          <b-col offset-md="12">
            <b-button
              v-if="materialRequestSent==false"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormCheckbox,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'

  import { ref } from '@vue/composition-api'

  const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const materialRequestTo = ''
  const materialRequestCc = ''
  const materialRequestReason = ''
  const materialRequestQtySent = 0
  const materialRequestStatus = ''
  const materialRequestId = ''
  const materialRequestSent = false
  const submitButtonDisabled = false
  export default {
    components: {
      BFormCheckbox,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        submitButtonDisabled,
     materialRequestId,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      materialRequestTo,
      materialRequestCc,
      materialRequestReason,
      materialRequestQtySent,
      materialRequestStatus,
      materialRequestSent,
      }
    },
    mounted() {
        this.getItems()
        this.getDetail()
    },
    methods: {
    getDetail() {
      const item = this.$cookies.get('materialRequestSend')
      console.log(item)
      this.modalDetailShow = true
      this.materialRequestTo = item.material_request_to
      this.materialRequestCc = item.material_request_cc
      this.materialRequestReason = item.material_request_reason
      this.materialRequestId = item.material_request_id
      const id = item.material_request_id
      this.materialRequestId = id
      this.materialRequestSent = item.material_request_is_accepted_by_warehouse
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_DETAILS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
        const itemsJoin = response.data.data.map(elem => (
            {
                unit_name: elem.item.unit ? elem.item.unit.unit_name : '-', label: elem.item.item_name, value: elem.material_request_detail_item_id, qty: elem.material_request_detail_item_qty_requested,qty_accepted: elem.material_request_detail_item_qty_accepted, qty_sent: elem.material_request_detail_item_qty_sent > 0 ? elem.material_request_detail_item_qty_sent : elem.material_request_detail_item_qty_accepted,
            }
        ))
        this.chosens = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
    getItems() {
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                const itemsJoin = response.data.data.map(elem => (
                  { label: elem.item_name, value: elem.item_id }
                ))
                this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Data Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Data Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Settings Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    submitEditMaterialRequest() {
      this.submitButtonDisabled = true
      this.chosens.map(elem => {
        this.materialRequestQtySent += parseInt(elem.qty_sent)
      })
        const body = {
          material_request_id: this.materialRequestId,
          material_request_to: this.materialRequestTo,
          material_request_cc: this.materialRequestCc,
          material_request_reason: this.materialRequestReason,
          material_request_qty_sent: this.materialRequestQtySent,
          material_request_is_accepted_by_warehouse: true,
          material_request_items: JSON.stringify(this.chosens),
          time: moment(),
        }
        console.log(body)
      axios
        .post(`${process.env.VUE_APP_API_URL}/transactions/material_requests/edit`, body, { headers })
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.materialRequestQtySent = 0
            this.materialRequestReason = ""
            this.materialRequestCc = ""
            this.materialRequestTo = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Material Request Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-material-request-list' })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Material Request Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
            })
            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
        previous() {
            this.$router.push({ name: 'apps-material-request-list' })
        },
    },
  }
  </script>
